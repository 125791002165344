import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import * as Styled from './Content.styled';

export function Content() {
  const { t } = useTranslate();

  const [brandConfig] = useStore((state) => [state.brandConfig]);
  const translationkeys = brandConfig.content;
  //const backgroundUrl = translationkeys.hero.backgroundUrl;

  return (
    <Styled.Container>
      <Styled.InnerContainer>
        <Styled.Description
          dangerouslySetInnerHTML={{
            __html: t(translationkeys.booking.content.details.be_sure.title),
          }}
        />
        <Styled.ConditionsList>
          {translationkeys.booking.content.details.be_sure.bullets.map(
            (e, index) => (
              <li
                key={`promo_conditions_${index}`}
                dangerouslySetInnerHTML={{
                  __html: t(e.key),
                }}
              />
            )
          )}
        </Styled.ConditionsList>
        <Styled.Description
          dangerouslySetInnerHTML={{
            __html: t(translationkeys.booking.content.details.disclaimer),
          }}
        />
        <Styled.Description
          dangerouslySetInnerHTML={{
            __html: t(translationkeys.booking.content.details.book_with_us),
          }}
        />
        <Styled.Description
          dangerouslySetInnerHTML={{
            __html: t(translationkeys.booking.content.details.remember),
          }}
        />
        <Styled.Description
          dangerouslySetInnerHTML={{
            __html: t(
              translationkeys.booking.content.details.expiration_warning
            ),
          }}
        />
        {/*<Styled.Button
          style={{
            backgroundImage: `url(${backgroundUrl})`,
          }}
          variant="text"
          onClick={() =>
            window.open(translationkeys.content.rules.cta.url, '_blank')
          }
        >
          {t(translationkeys.actions.book_now)}
        </Styled.Button>*/}
        <Styled.Title>
          {t(translationkeys.booking.content.promo_conditions.title)}
        </Styled.Title>
        <Styled.ConditionsList>
          {translationkeys.booking.content.promo_conditions.steps.map(
            (e, index) => (
              <li
                key={`promo_conditions_${index}`}
                dangerouslySetInnerHTML={{
                  __html: t(e.key, { ...e.placeholders }),
                }}
              />
            )
          )}
        </Styled.ConditionsList>
      </Styled.InnerContainer>
    </Styled.Container>
  );
}
