import { Favicon } from '@/components/Favicon';
import { StoreProvider } from '@/store/storeProvider';
import { getBrandConfig } from '@/store/utils/getBrandConfig';
import { MainPage } from '@/views/MainPage';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CookieConsent } from './components/CookieConsent/CookieConsent';
import { ErrorScreen } from './ErrorScreen/ErrorScreen';
import { FlagProvider } from './providers/FlagProvider';
import { MuiProvider } from './providers/MuiProvider';
import { TolgeeProvider } from './providers/TolgeeProvider';
import { BookingPage } from './views/BookingPage';
import { FAQPage } from './views/FAQPage';

dayjs.extend(utc);
dayjs.extend(timezone);

export function App() {
  const initialStateMemoized = React.useMemo(
    () => ({ brandConfig: getBrandConfig }),
    []
  );

  if (!getBrandConfig) {
    return <ErrorScreen />;
  }

  return (
    <BrowserRouter>
      <StoreProvider initialState={initialStateMemoized}>
        <FlagProvider>
          <TolgeeProvider>
            <MuiProvider>
              <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/faq" element={<FAQPage />} />
                <Route path="/booking" element={<BookingPage />} />
              </Routes>
              <Favicon />
              <CookieConsent />
            </MuiProvider>
          </TolgeeProvider>
        </FlagProvider>
      </StoreProvider>
    </BrowserRouter>
  );
}
