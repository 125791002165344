import RefreshIcon from '@mui/icons-material/Refresh';
import * as Styled from './ErrorScreen.styled';

export const ErrorScreen = () => {
  return (
    <Styled.Container>
      <Styled.Card>
        <Styled.ErrorImage />
        <Styled.Button onClick={() => location.reload()}>
          <RefreshIcon />
        </Styled.Button>
      </Styled.Card>
    </Styled.Container>
  );
};
