import styled from '@emotion/styled';

export const ErrorImage = styled.div`
  width: 300px;
  height: 200px;
  background-image: url('https://assets.staticroot.com/lmn-assets-ui/4/alert/generic-error/pink.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: #f5f5f5;
  align-items: center;
  justify-content: center;
`;
export const Card = styled.div`
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  gap: 8px;
`;

export const Button = styled.button`
  align-items: center;
  background: rgb(242, 1, 125);
  border-radius: 8px;
  border: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  height: 100%;
  min-height: 56px;
  line-height: 56px;
  justify-content: center;
  outline: none;
  padding: 0px;
  transition: all 0.2s ease 0s;
  width: 100%;
`;
