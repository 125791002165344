import {
  CreateFFVoucherResponseDto,
  FreeFlightVoucherInput,
  RoiwardService,
} from '@/services/freeflight-api';

export const createVoucher = async (
  requestBody: FreeFlightVoucherInput
): Promise<CreateFFVoucherResponseDto> => {
  var response =
    await RoiwardService.postApiServicesAppRoiwardCreateFreeFlightVoucher({
      requestBody,
    });

  return response;
};
