import { useStore } from '@/store/useStore';
import { Backdrop, Button } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import * as Styled from './CookieConsent.styled';
import { getCookie, setCookie } from './utils/cookieUtils';

const cookieName = 'cookie_consent';

export const CookieConsent = () => {
  const { t } = useTranslate();
  const [brandConfig] = useStore((state) => [state.brandConfig]);
  const translationkeys = brandConfig.content;

  const cookieConsentValue = getCookie(cookieName);
  const [open, setOpen] = useState(
    cookieConsentValue === 'true' ? false : true
  );

  const handleAcceptCookie = () => {
    setCookie(cookieName, 'true', 365);
    setOpen(false);
  };

  return (
    <Backdrop sx={() => ({ zIndex: 300 })} open={open}>
      <Styled.Card>
        <Styled.Title>{t(translationkeys.cookie_policy.title)}</Styled.Title>
        <Styled.Description
          dangerouslySetInnerHTML={{
            __html: t(
              translationkeys.cookie_policy.description.key,
              translationkeys.cookie_policy.description.placeholders
            ),
          }}
        ></Styled.Description>
        <Styled.ButtonWrapper onClick={() => handleAcceptCookie()}>
          <Button variant="contained">{t('cookie-consent.accept')}</Button>
        </Styled.ButtonWrapper>
      </Styled.Card>
    </Backdrop>
  );
};
