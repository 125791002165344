import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslate } from '@tolgee/react';
import * as React from 'react';
import * as Styled from './Modal.styled';
import { ModalProps } from './Modal.types';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GENERIC_TITLE = 'general.dialog.title';

export function Modal({
  title,
  children,
  isModalOpen,
  onCloseModal,
}: ModalProps) {
  const { t } = useTranslate();
  return (
    <Styled.Dialog
      open={isModalOpen}
      TransitionComponent={Transition}
      onClose={onCloseModal}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>
        {t(title ?? GENERIC_TITLE)}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCloseModal}
        disableRipple
        sx={{
          position: 'absolute',
          left: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>{children}</DialogContent>
    </Styled.Dialog>
  );
}
