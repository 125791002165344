import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import './index.css';

Sentry.init({
  dsn: 'https://bfef7772a1d67b8f003d2fa92985b670@o4507962379337728.ingest.de.sentry.io/4508042537664592',
  environment: window._env_.VITE_SENTRY_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
