import { useStore } from '@/store/useStore';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import Popper from '@mui/material/Popper';
import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import * as Styled from './Header.styled';
export function Header({
  showSearchSummary = false,
}: {
  showSearchSummary?: boolean;
}) {
  const brandConfig = useStore((state) => state.brandConfig);
  const { t } = useTranslate();
  const { headerColor, primaryColor } = brandConfig.theme.data.attributes;
  const translationkeys = brandConfig.content;
  const logoPrimaryUrl =
    brandConfig.theme.data.attributes.logoPrimaryUrl.data.attributes.url;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div>
      <Styled.Container
        style={{
          backgroundColor: headerColor,
        }}
      >
        <Styled.LogoContainer $hideLogo={showSearchSummary} href="/">
          <Styled.Logo src={logoPrimaryUrl} alt="logo" />
        </Styled.LogoContainer>
      </Styled.Container>
      <Styled.Menu>
        <Styled.ButtonsContainer>
          <Styled.Button href="/">
            {t(translationkeys.menu.registration)}
          </Styled.Button>
          <Styled.Button href="/booking">
            {t(translationkeys.menu.book_now)}
          </Styled.Button>
          <Styled.Button href="/faq">
            {t(translationkeys.menu.faq)}
          </Styled.Button>
        </Styled.ButtonsContainer>

        <Styled.AssisanceButton
          onClick={handleClick}
          style={{ backgroundColor: primaryColor }}
        >
          {t(translationkeys.menu.support.key)}
        </Styled.AssisanceButton>
        <Popper id={id} open={open} anchorEl={anchorEl}>
          <Styled.AssistanceBox>
            <Styled.CallToButton
              style={{ backgroundColor: primaryColor }}
              href={`callto:${translationkeys.menu.support.phone}`}
            >
              <PhoneIcon />
              {t(translationkeys.menu.support.phone)}
            </Styled.CallToButton>
            <Styled.CallToButton
              style={{ backgroundColor: primaryColor }}
              href={`mailto:${translationkeys.menu.support.email}`}
            >
              <EmailIcon />
              {t(translationkeys.menu.support.email)}
            </Styled.CallToButton>
          </Styled.AssistanceBox>
        </Popper>
      </Styled.Menu>
    </div>
  );
}
