import { CircularProgress, InputAdornment } from '@mui/material';
import { ValidationIcon } from '../components/LmnInput/elements';

export const getEndAdornment = (pinCodeError: string, loading: boolean) => {
  if (pinCodeError === 'Ok') {
    return (
      <InputAdornment position="end">
        <ValidationIcon />
      </InputAdornment>
    );
  }

  if (loading) {
    return (
      <InputAdornment position="end">
        <CircularProgress size="20px" />
      </InputAdornment>
    );
  }
  return null;
};
