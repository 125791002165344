import { useStore } from '@/store/useStore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LoadingButton } from '@mui/lab';
import { Alert, Checkbox, FormGroup } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { useEffect, useState } from 'react';
import { Modal } from '../Modal';
import * as Styled from './RedeemForm.styled';
import { LmnInput } from './components/LmnInput';
import { SuccessMessage } from './components/SuccessMessage/SuccessMessage';
import { useRedeemCode } from './hooks/useRedeemCode';
import { getEndAdornment } from './utils/getAdornment';

export const textRegex =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð\s]+$/;
export const phoneRegex = /^\+?(\d{1,3})?\s?\d{3}\s?\d{3,10}$/;
export const emailRegex =
  /^[a-zA-Z0-9._%+-]+@(?!alice\.it$|tim\.it$|tin\.it$)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const pincodeLength = 13;

function esperarTresSegundos(): Promise<{ success: boolean }> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ success: false });
    }, 3000);
  });
}

export function RedeemForm() {
  const { t } = useTranslate();
  const { redeemPincode, checkPinCode } = useRedeemCode();
  const [pinCode, setPinCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingRedeem, setLoadingRedeem] = useState(false);
  const [redeemSuccess, setRedeemSuccess] = useState(false);
  const [openConditionsModal, setOpenConditionsModal] = useState(false);
  const [pinCodeError, setPinCodeError] = useState(undefined);

  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [promoConditionsAccepted, setPromoConditionsAccepted] = useState(false);

  const [brandConfig] = useStore((state) => [state.brandConfig]);
  const translationkeys = brandConfig.content;

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    email2: '',
    phone: '',
  });

  const [inputErrors, setInputErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    email2: false,
    phone: false,
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (name === 'pinCode') {
      setPinCode(value);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleValidate = (regex: RegExp) => (e: any) => {
    const { name, value } = e.target;

    switch (name) {
      case 'email2':
        setInputErrors({ ...inputErrors, [name]: formData.email !== value });
        break;
      default:
        setInputErrors({ ...inputErrors, [name]: !regex.test(value) });
        break;
    }
  };

  const handleValidatePin = async () => {
    if (pinCode.length === pincodeLength) {
      const pinCodeStatus = await checkPinCode(pinCode);
      setPinCodeError(pinCodeStatus);
    } else {
      setPinCodeError('Length');
    }
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await handleValidatePin();
      setLoading(false);
    };

    const timer = setTimeout(() => {
      pinCode.length > 0 && getData();
    }, 1000);

    return () => clearTimeout(timer);
  }, [pinCode]);

  const handleRedeemCode = async () => {
    setLoadingRedeem(true);
    const pinCodeStatus = await checkPinCode(pinCode);
    if (pinCodeStatus === 'Ok') {
      const response = await redeemPincode(formData, pinCode);
      if (response.success === true) {
        setOpenConditionsModal(false);
        setRedeemSuccess(true);
      } else {
        setPinCodeError('RedeemError');
      }
    } else {
      setPinCodeError(pinCodeStatus);
    }
    setLoadingRedeem(false);
  };

  const handleRegister = async () => {
    setLoadingRedeem(true);
    const pinCodeStatus = await checkPinCode(pinCode);
    if (pinCodeStatus === 'Ok') {
      setOpenConditionsModal(true);
    } else {
      setPinCodeError(pinCodeStatus);
    }
    setLoadingRedeem(false);
  };

  const validateRedeemButton = () => {
    if (
      Object.values(inputErrors).every((value) => value === false) &&
      Object.values(formData).every((value) => !!value)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const formAvailable = !(pinCode && pinCodeError === 'Ok');

  if (redeemSuccess) {
    return <SuccessMessage />;
  }

  return (
    <Styled.Container>
      <Styled.Card>
        <LmnInput
          label={t(translationkeys.form.insert_pin_code)}
          variant="filled"
          name="pinCode"
          value={pinCode}
          onChange={handleChange}
          autoComplete="false"
          fullWidth
          InputProps={{
            endAdornment: getEndAdornment(pinCodeError, loading),
          }}
        />
        {!(pinCodeError === 'Ok' || pinCodeError === undefined) && (
          <Alert
            onClose={() => setPinCodeError(undefined)}
            severity="warning"
            icon={<InfoOutlinedIcon />}
          >
            {t(translationkeys.form.validation.pin_code_error[pinCodeError])}
          </Alert>
        )}
      </Styled.Card>
      <Styled.FormContainer>
        <Styled.Card $hidden={formAvailable}>
          <Styled.Row>
            <LmnInput
              label={t(translationkeys.form.firstName)}
              variant="filled"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              onBlur={handleValidate(textRegex)}
              autoComplete="false"
              fullWidth
              disabled={formAvailable}
              error={inputErrors.firstName}
              helperText={
                inputErrors.firstName &&
                t(translationkeys.form.validation.firstName)
              }
            />
            <LmnInput
              label={t(translationkeys.form.lastName)}
              variant="filled"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              onBlur={handleValidate(textRegex)}
              autoComplete="false"
              fullWidth
              disabled={formAvailable}
              error={inputErrors.lastName}
              helperText={
                inputErrors.lastName &&
                t(translationkeys.form.validation.lastName)
              }
            />
          </Styled.Row>
          <Styled.Row>
            <LmnInput
              label={t(translationkeys.form.email)}
              variant="filled"
              name="email"
              value={formData.email}
              onChange={handleChange}
              onBlur={handleValidate(emailRegex)}
              autoComplete="false"
              fullWidth
              disabled={formAvailable}
              error={inputErrors.email}
              helperText={
                inputErrors.email && t(translationkeys.form.validation.email)
              }
            />
            <LmnInput
              label={t(translationkeys.form.repeat_email)}
              variant="filled"
              name="email2"
              value={formData.email2}
              onChange={handleChange}
              onBlur={handleValidate(emailRegex)}
              autoComplete="false"
              fullWidth
              disabled={formAvailable}
              error={inputErrors.email2}
              helperText={
                inputErrors.email2 &&
                t(translationkeys.form.validation.repeat_email)
              }
            />
          </Styled.Row>
          <Styled.Row>
            <LmnInput
              label={t(translationkeys.form.phone)}
              placeholder="+39 123 4567890"
              variant="filled"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              onBlur={handleValidate(phoneRegex)}
              autoComplete="false"
              fullWidth
              disabled={formAvailable}
              error={inputErrors.phone}
              helperText={
                inputErrors.phone && t(translationkeys.form.validation.phone)
              }
            />
            <Styled.Button
              variant="contained"
              disabled={formAvailable || validateRedeemButton()}
              onClick={handleRegister}
              loading={loadingRedeem}
            >
              {t('form.register')}
            </Styled.Button>
          </Styled.Row>
        </Styled.Card>
      </Styled.FormContainer>
      <Modal
        title={t('dialog.title.terms_and_conditions')}
        isModalOpen={openConditionsModal}
        onCloseModal={() => setOpenConditionsModal(false)}
      >
        <FormGroup>
          <Styled.FormControlLabel
            onChange={(_, checked) => setPrivacyPolicyAccepted(checked)}
            control={<Checkbox />}
            label={
              <div
                dangerouslySetInnerHTML={{
                  __html: t(
                    translationkeys.form.privacyPolicy.key,
                    translationkeys.form.privacyPolicy.placeholders
                  ),
                }}
              />
            }
            value={privacyPolicyAccepted}
          />
          <Styled.FormControlLabel
            onChange={(_, checked) => setPromoConditionsAccepted(checked)}
            control={<Checkbox />}
            label={
              <div
                dangerouslySetInnerHTML={{
                  __html: t(
                    translationkeys.form.promoConditions.key,
                    translationkeys.form.promoConditions.placeholders
                  ),
                }}
              />
            }
            value={promoConditionsAccepted}
          />
        </FormGroup>
        <Styled.ButtonContainer>
          <LoadingButton
            variant="contained"
            disabled={!(privacyPolicyAccepted && promoConditionsAccepted)}
            onClick={handleRedeemCode}
            loading={loadingRedeem}
            fullWidth
          >
            {t('form.register')}
          </LoadingButton>
        </Styled.ButtonContainer>
      </Modal>
    </Styled.Container>
  );
}
