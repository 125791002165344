import { useStore } from '@/store/useStore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useTranslate } from '@tolgee/react';
import * as Styled from './Questions.styled';

export function Questions() {
  const { t } = useTranslate();

  const [brandConfig] = useStore((state) => [state.brandConfig]);
  const translationkeys = brandConfig.content;

  return (
    <Styled.Container>
      <Styled.InnerContainer>
        <Styled.Title>{t(translationkeys.faq.content.title)}</Styled.Title>
        <div>
          {translationkeys.faq.content.questions.map((el, index) => (
            <Accordion defaultExpanded={index === 0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <b>{t(el.title)}</b>
              </AccordionSummary>
              <AccordionDetails
                dangerouslySetInnerHTML={{
                  __html: t(el.description.key, {
                    ...el.description.placeholders,
                  }),
                }}
              ></AccordionDetails>
            </Accordion>
          ))}
        </div>
      </Styled.InnerContainer>
    </Styled.Container>
  );
}
