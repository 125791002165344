import { DocumentTitle } from '@/components/DocumentTitle';
import { Header } from '@/components/Header';
import { Hero } from '@/components/Hero';
import { LmnFooter } from '@/components/LmnFooter';
import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import { Questions } from './components/Questions';

export function FAQPage() {
  const [brandConfig] = useStore((state) => [state.brandConfig]);
  const { t } = useTranslate();

  const { content } = brandConfig;

  return (
    <>
      <DocumentTitle title={t(content.general.tabName.key, content.general.tabName.placeholders)} />
      <Header />
      <Hero
        title={t(content.faq.title)}
        backgroundUrl={content.hero.backgroundUrl}
      ></Hero>
      <Questions />
      <LmnFooter />
    </>
  );
}
