import styled from '@emotion/styled';
import { TextField } from '@mui/material';

const LmnInput = styled(TextField)`
  label {
    color: #74747c !important;
    padding-left: 7px;
  }
`;

export { LmnInput };
