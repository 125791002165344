import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { LoadingButton as LoadingButtonMui } from '@mui/lab';
import {
  Alert as MuiAlert,
  FormControlLabel as MuiFormControlLabel,
} from '@mui/material';

export const Title = styled.span`
  color: white;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 38px;
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
`;

export const SuccessMessage = styled(Title)`
  font-size: 32px;
  color: #3c3c3c;
  margin-bottom: 0px;
`;

export const SubtitleMessage = styled.span`
  color: #3c3c3c;
  font-family: Open Sans;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  text-align: center;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

export const Card = styled.div<{ $hidden?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: #fff;
  width: 100%;
  border-radius: 16px;
  box-shadow:
    rgba(0, 0, 0, 0.04) 0px 10px 20px,
    rgba(0, 0, 0, 0.04) 0px 2px 10px,
    rgba(0, 0, 0, 0.02) 0px 0px 4px;
  transition: filter 0.5s ease-in;

  ${({ $hidden }) =>
    $hidden &&
    css`
      cursor: not-allowed;
    `}
`;
export const SuccessCard = styled(Card)`
  max-width: 700px;
`;

export const Row = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const FormContainer = styled.div`
  position: relative;
`;

export const Alert = styled(MuiAlert)`
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Button = styled(LoadingButtonMui)`
  height: 58px;
  border-radius: 8px;
  width: calc(100% - 32px);

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  & .MuiFormControlLabel-label  {
    flex: 1;
  }
  margin-bottom: 16px;
`;
