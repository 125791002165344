import * as Styled from './Hero.styled';
import { HeroProps } from './Hero.types';

export function Hero({ backgroundUrl, title, children }: HeroProps) {
  return (
    <Styled.Container
      style={{
        backgroundImage: `url(${backgroundUrl})`,
      }}
    >
      <Styled.InnerContainer>
        <Styled.Title>{title}</Styled.Title>
        {children}
      </Styled.InnerContainer>
    </Styled.Container>
  );
}
