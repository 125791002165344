import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
  overflow-y: hidden;
  overflow-x: hidden;
`;

export const Card = styled.div`
  border-radius: 10px;
  position: fixed;
  bottom: 16px;
  left: 16px;
  padding: 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-right: 16px;
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
