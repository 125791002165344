window._env_ = {
  VITE_SERVICE_BASE_URL_WELFARE: "https://welfare-api.lastminute.com",
  VITE_APP_TOLGEE_API_URL: "https://tolgee.dative.cloud",
  VITE_APP_TOLGEE_API_KEY: "tgpak_hfpwsnjznj2gsyrvgbuxe3tpofsxm2rtge2xgytkgvvtg",
  VITE_APP_TOLGEE_PROD_URL: "https://tolgee-content-roiward.s3.eu-west-1.amazonaws.com/d13641e00a077e7ca8d8910216bd041d",
  VITE_DOMAIN_STRAPI: "unisalute.lastminute.com",
  VITE_UNLEASH_ENV: "development",
  VITE_SENTRY_ENV: "development",
  VITE_UNLEASH_API_URL: "https://unleash.dative.cloud/api/frontend",
  VITE_UNLEASH_CLIENT_KEY: "default:development.ef2e4f10ca7d3d221b9005cdbc2f09747a8418494da587ea0b1fde75",
  VITE_MAPBOX_KEY: "pk.eyJ1IjoibGFzdG1pbnV0ZSIsImEiOiJjbHd1a3g5cHkwZ2tzMm5yMW5vc2duNHd4In0.49AJh9mjArPBJ6PJrxhS0w",
  VITE_SERVICE_BASE_URL_FREEFLIGHT: "https://freeflight-api.dative.es",
  SENTRY_AUTH_TOKEN: "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",
}
