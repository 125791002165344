import { useTranslate } from '@tolgee/react';
import * as Styled from '../../RedeemForm.styled';
import SuccessIcon from './assets/SuccessIcon';

export function SuccessMessage() {
  const { t } = useTranslate();

  return (
    <Styled.SuccessContainer>
      <Styled.SuccessCard>
        <Styled.IconContainer>
          <SuccessIcon />
        </Styled.IconContainer>
        <Styled.SuccessMessage>{t('form.success.title')}</Styled.SuccessMessage>
        <Styled.SubtitleMessage>
          {t('form.success.check_spam')}
        </Styled.SubtitleMessage>
      </Styled.SuccessCard>
    </Styled.SuccessContainer>
  );
}
