import styled from '@emotion/styled';
import DialogMaterial from '@mui/material/Dialog';

export const Dialog = styled(DialogMaterial)`
  .MuiPaper-root {
    border-radius: 16px;
    width: 100%;
    max-width: 600px;
    margin: 0;
    @media (max-width: 600px) {
      position: absolute;
      bottom: 0px;
      border-radius: 16px 16px 0px 0px;
    }
  }

  .MuiDialogTitle-root {
    box-shadow:
      0px 10px 20px rgba(0, 0, 0, 0.04),
      0px 2px 10px rgba(0, 0, 0, 0.04),
      0px 0px 4px rgba(0, 0, 0, 0.02);
    font-weight: 700;
    font-size: 16px;
  }
`;
