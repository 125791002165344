import SvgIcon from '@mui/material/SvgIcon';

export default function SuccessIcon() {
  return (
    <SvgIcon style={{ width: 250, height: 200 }}>
      <svg
        id="LM"
        xmlns="http://www.w3.org/2000/svg"
        width="420"
        height="300"
        viewBox="0 0 420 300"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="165.17"
            y1="73.47"
            x2="337.32"
            y2="73.47"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#f766b1" />
            <stop offset="1" stop-color="#f2007d" />
          </linearGradient>
        </defs>
        <path
          d="m180.29,30.08c20.33-30.39,33.84,22,51.19,32.53,19.44,11.79,42.62,17.9,65.66,17.3,15.01-.39,29.95-3.55,44.92-2.49,14.97,1.06,30.88,7.59,36.98,20.66,7.76,16.64-9.78,40.26-19.98,55.65-10.2,15.39-20.39,34.71-11.54,50.85,7.16,13.07,24.43,18.63,32.3,31.33,9.39,15.17,1.33,36.05-13.69,46.3-15.02,10.25-34.77,11.9-53.17,10.28-20.67-1.82-40.81-7.26-61.42-9.63-54.41-6.25-109.1,9.1-163.88,7.88-8.9-.2-17.99-.88-26.16-4.25-13.97-5.77-23.53-18.94-27.36-32.98-3.84-14.04-2.61-28.91.2-43.17,3.16-16.05,8.28-31.74,15.24-46.66,5.95-12.78,13.41-25.69,12.94-39.67-.37-11.07-5.72-21.36-8.38-32.14-2.41-9.78-4.22-20.99-2.44-30.89,4.62-25.77,26.24-28.89,37.91-20.67,19.39,13.65,80,5.76,90.69-10.22Z"
          fill="#f0edf4"
        />
        <g>
          <path
            d="m165.17,110.34c-11.98,0-21.94,8.67-23.94,20.08-.42,2.41-2.43,4.22-4.87,4.22h-46.2c-6.65,0-12.1-5.44-12.1-12.1V24.4c0-6.65,5.44-12.1,12.1-12.1h46.2c2.45,0,4.45,1.81,4.87,4.22,2,11.41,11.95,20.08,23.94,20.08v73.74Z"
            fill="#694f8d"
          />
          <path
            d="m165.17,110.34c11.98,0,21.94,8.67,23.94,20.08.42,2.41,2.43,4.22,4.87,4.22h131.28c6.64,0,12.07-5.43,12.07-12.07V24.37c0-6.64-5.43-12.07-12.07-12.07h-131.28c-2.45,0-4.45,1.81-4.87,4.22-2,11.41-11.95,20.08-23.94,20.08v73.74Z"
            fill="url(#linear-gradient)"
          />
          <g>
            <path
              d="m161.09,112.06v-11.2c0-2.21,8.16-2.21,8.16,0v11.2c0,2.21-8.16,2.21-8.16,0Z"
              fill="#f0edf4"
            />
            <path
              d="m161.09,89.95v-11.2c0-2.21,8.16-2.21,8.16,0v11.2c0,2.21-8.16,2.21-8.16,0Z"
              fill="#f0edf4"
            />
            <path
              d="m161.09,67.84v-11.2c0-2.21,8.16-2.21,8.16,0v11.2c0,2.21-8.16,2.21-8.16,0Z"
              fill="#f0edf4"
            />
            <path
              d="m161.09,45.73v-11.2c0-2.21,8.16-2.21,8.16,0v11.2c0,2.21-8.16,2.21-8.16,0Z"
              fill="#f0edf4"
            />
          </g>
          <g>
            <path
              d="m270.41,56.66l-33.72-18.86c-.67-.38-1.49-.39-2.18-.03l-3.31,1.75c-1.3.69-1.62,2.42-.64,3.52l20.48,23.86"
              fill="#442370"
            />
            <path
              d="m295.8,49.91c-1.84-3.48-13.21.3-13.21.3l-12.19,6.45-33.08,17.5-15.97-4.92-3.48,1.84,13.51,12.91,3.07,18.43,3.48-1.84,4.92-15.97,13.97-7.39,7.92,30.52c.37,1.42,1.97,2.13,3.27,1.44l3.32-1.76c.68-.36,1.14-1.04,1.2-1.81l3.39-38.49,12.19-6.45s9.52-7.27,7.68-10.75Z"
              fill="#442370"
            />
          </g>
        </g>
        <g>
          <path
            d="m268.6,151.53l-8.22-4.39-6.03,44.01c-7.49,1.46-17.17,8.68-23.55,12.97-3.05-1.22-6.4-2.25-10.48-3.45-7.4-2.18-21.53-3.01-29.99-1.56-9.46,1.63-16.3,3.89-21.25,6.26-.38-.28-.74-.52-1.03-.71-6.14-3.84-17.38-12.81-25.82-14.45l-6.03-44.01-8.22,4.39s-10.46,29.34,4.04,56.23c4.08,7.57,21.34,25.97,31.27,30.27l15.45,46.88s18.69-1.85,31.33-2.34c10.63-.41,27.58.06,27.58.06l-1.78-44.99c10.09-5.91,24.95-22,28.7-28.96,14.5-26.9,4.04-56.23,4.04-56.23Z"
            fill="#4b74cd"
          />
          <g>
            <path
              d="m214.51,200.38c-.16-.03-.36-.06-.53-.08l-3.71-30.42s-1.86.85-8.08.29c-7.76-.7-8.38,5.99-8.38,5.99l-2.01,22.83c-.66,0-1.47.13-1.47.13,0,0,3.43,14.46,14.21,13.49,13.02-1.17,9.98-12.22,9.98-12.22Z"
              fill="#ebb9b2"
            />
            <g>
              <path
                d="m202.74,182.36c.71-12.36-10.49-21.97-10.49-21.97,4.34-13.82,10.23-13.73,24.81-12.06,3.59.33,11.75,1.25,10.38,17.58-.43,5.11,2.94,5.44,2.72,9.16-.62,10.4-7.75,21.97-11.14,22.32l-17.03-1.89.75-13.14Z"
                fill="#ebb9b2"
              />
              <path
                d="m229.79,178.25c-.01-1.96-3.66-2.87-9.03-1.92-8.05,1.43-10.27,8.15-10.27,8.15,0,0-4.18-1.12-5.59-7.02-.28-1.16-2.89-13.88-.68-18.52,6.53-13.74,18.86.71,22.85-1.96,2.4-1.61.24-16.26-15.67-16.09-30.67.33-30.25,26.9-25.84,33.82,3.75,5.89,4.15,20.34,8.58,14.56,1.08-1.41,1.92-6.51,2.43-9.47.53-3.08-2.49-14.71,2.97-4.73,0,0,.64,15.93.89,18.41.38,3.86,5.92,5.16,12.35,6.89,9.16,2.46,12.77-1.32,14.97-4.26,4.27-5.72,2.09-12.28,2.05-17.86Z"
                fill="#442370"
              />
              <path
                d="m197.14,178.72c-.99,1.12-5.4-1.58-5.42-5.96-.02-3.53,5.94-7.05,7.84,2.86"
                fill="#ebb9b2"
              />
              <path
                d="m217.01,182.9c-.13,1.08,1.92,2.96,4.54,2.98,3.79.03,4.48-2.5,4.6-3.58.13-1.08-1.56,0-4.17-.32-3.83-.49-4.85-.16-4.98.92Z"
                fill="#ebb9b2"
              />
              <g>
                <path
                  d="m215.04,168.56c.68.05,1.36.25,1.96.61.33.2.43.63.23.96-.2.33-.63.43-.96.23-1.16-.72-2.89-.46-3.77.56-.25.29-.69.32-.98.07-.29-.25-.32-.69-.07-.98.89-1.03,2.26-1.54,3.59-1.45Z"
                  fill="#b08b85"
                />
                <path
                  d="m226.11,167.84c.68.05,1.36.25,1.96.61.33.2.43.63.23.96-.2.33-.63.43-.96.23-1.16-.72-2.89-.46-3.77.56-.25.29-.69.32-.98.07-.29-.25-.32-.69-.07-.98.89-1.03,2.26-1.54,3.59-1.45Z"
                  fill="#b08b85"
                />
                <path
                  d="m209.34,166.33c.26-.19,1.3-1.1,3.56-1.55,2.27-.45,3.89,0,3.89,0"
                  fill="#442370"
                />
                <path
                  d="m222.35,164.74c.25-.09,1.3-.59,2.92-.3,1.62.29,2.44,1.19,2.44,1.19"
                  fill="#442370"
                />
              </g>
            </g>
          </g>
          <path
            d="m136.19,146.21s4.74-20.13,2.56-22c-2.22-1.9-8.32.86-11.4,7-1.94,3.87.62,19.38.62,19.38l8.22-4.39Z"
            fill="#ebb9b2"
          />
          <path
            d="m260.38,147.14s-4.74-20.13-2.56-22c2.22-1.9,8.32.86,11.4,7,1.94,3.87-.62,19.38-.62,19.38l-8.22-4.39Z"
            fill="#ebb9b2"
          />
          <circle cx="204.43" cy="217.65" r="1.86" fill="#f0edf4" />
          <circle cx="205.02" cy="225.44" r="1.86" fill="#f0edf4" />
          <circle cx="205.39" cy="231.88" r="1.86" fill="#f0edf4" />
        </g>
        <g>
          <path
            d="m153.14,127.58c-.2.52-.22,1.12-.01,1.68.45,1.25,1.84,1.9,3.09,1.44,2.62-.95,4.97-1.09,6.78-.4.35.13.47.29.98,1.24.74,1.38,1.85,3.47,5.19,4.75,2.32.89,4.18.26,5.55-.2,1.18-.4,1.74-.56,2.56-.23.11.1.5.71.76,1.12,1.36,2.15,3.88,6.14,10.98,3.48,1.24-.47,1.88-1.85,1.41-3.1-.47-1.25-1.85-1.88-3.1-1.41-3.43,1.28-4.03.34-5.22-1.55-.71-1.12-1.51-2.39-3-3-2.52-1.03-4.49-.37-5.93.12-1.17.4-1.64.51-2.28.27-1.66-.64-2.06-1.38-2.66-2.52-.6-1.13-1.42-2.68-3.5-3.47-2.91-1.12-6.42-.99-10.15.37-.68.25-1.19.77-1.43,1.4Z"
            fill="#aee5e2"
          />
          <path
            d="m191.44,47.17c.51-.22.95-.62,1.22-1.17.58-1.2.07-2.64-1.12-3.21-2.52-1.21-4.25-2.79-5.02-4.58-.15-.34-.12-.54.21-1.56.48-1.49,1.19-3.74-.22-7.03-.98-2.28-2.74-3.17-4.02-3.83-1.11-.57-1.62-.85-1.95-1.67,0-.15.16-.85.27-1.32.59-2.47,1.69-7.07-5.17-10.29-1.2-.56-2.64-.05-3.2,1.15-.57,1.2-.05,2.64,1.15,3.2,3.32,1.56,3.06,2.65,2.54,4.81-.31,1.29-.66,2.75-.05,4.24,1.02,2.52,2.87,3.47,4.23,4.16,1.1.56,1.51.82,1.78,1.44.7,1.63.45,2.44.06,3.66-.39,1.22-.92,2.89-.04,4.93,1.23,2.86,3.78,5.29,7.35,7.01.65.32,1.38.31,2,.04Z"
            fill="#93ace1"
          />
          <path
            d="m299.04,222.23c.52.2,1.12.22,1.68,0,1.25-.46,1.89-1.84,1.43-3.09-.97-2.62-1.12-4.97-.43-6.78.13-.35.29-.47,1.23-.98,1.38-.74,3.46-1.86,4.73-5.21.88-2.32.24-4.18-.23-5.54-.41-1.18-.57-1.74-.24-2.56.1-.11.71-.5,1.11-.76,2.14-1.37,6.12-3.91,3.43-11-.47-1.24-1.86-1.87-3.11-1.4-1.24.47-1.87,1.86-1.4,3.11,1.3,3.43.36,4.03-1.52,5.23-1.12.71-2.38,1.52-2.98,3.01-1.02,2.52-.35,4.49.15,5.93.4,1.17.52,1.64.28,2.28-.63,1.66-1.37,2.06-2.5,2.67-1.13.61-2.67,1.44-3.46,3.52-1.1,2.92-.96,6.43.42,10.15.25.68.78,1.18,1.41,1.42Z"
            fill="#aee5e2"
          />
          <path
            d="m272.52,267.05c.52-.22.96-.61,1.22-1.16.59-1.19.1-2.64-1.1-3.22-2.51-1.23-4.23-2.83-4.98-4.62-.14-.34-.12-.55.22-1.56.49-1.49,1.23-3.74-.16-7.03-.96-2.29-2.71-3.2-3.99-3.86-1.11-.58-1.61-.87-1.94-1.69,0-.15.17-.86.28-1.32.61-2.46,1.74-7.05-5.09-10.33-1.2-.57-2.64-.07-3.21,1.13-.58,1.2-.07,2.64,1.13,3.21,3.3,1.59,3.04,2.67,2.5,4.84-.32,1.28-.68,2.74-.09,4.24.99,2.53,2.84,3.49,4.19,4.2,1.1.57,1.51.83,1.77,1.46.69,1.64.43,2.44.03,3.66-.4,1.22-.95,2.88-.08,4.93,1.21,2.87,3.73,5.32,7.3,7.07.65.32,1.38.32,2,.06Z"
            fill="#93ace1"
          />
          <path
            d="m68.98,218.14c.48.29,1.06.42,1.65.33,1.31-.22,2.2-1.45,1.99-2.77-.45-2.76-.15-5.09.87-6.74.19-.31.38-.41,1.4-.73,1.49-.47,3.75-1.17,5.63-4.22,1.3-2.11,1.03-4.06.82-5.49-.17-1.24-.23-1.82.25-2.56.12-.09.79-.36,1.24-.54,2.36-.94,6.75-2.68,5.45-10.15-.23-1.31-1.47-2.19-2.79-1.96-1.31.23-2.19,1.48-1.96,2.79.63,3.61-.41,4.02-2.48,4.84-1.23.49-2.62,1.04-3.5,2.39-1.48,2.28-1.19,4.34-.98,5.85.17,1.23.2,1.71-.16,2.29-.93,1.51-1.74,1.77-2.97,2.15-1.22.38-2.89.9-4.06,2.8-1.64,2.66-2.16,6.13-1.52,10.05.12.72.54,1.31,1.11,1.66Z"
            fill="#aee5e2"
          />
          <path
            d="m84.03,162.96c.47.3,1.05.43,1.65.34,1.31-.21,2.21-1.44,2-2.75-.44-2.76-.12-5.09.91-6.73.2-.31.38-.41,1.4-.72,1.5-.46,3.76-1.15,5.66-4.18,1.31-2.1,1.05-4.06.86-5.48-.17-1.24-.22-1.82.26-2.55.12-.09.79-.36,1.24-.53,2.37-.92,6.77-2.64,5.52-10.11-.22-1.31-1.46-2.2-2.77-1.98-1.31.22-2.2,1.46-1.98,2.77.61,3.61-.44,4.02-2.51,4.83-1.23.48-2.63,1.02-3.52,2.37-1.5,2.27-1.22,4.34-1.02,5.85.17,1.23.19,1.71-.17,2.29-.94,1.51-1.75,1.75-2.98,2.13-1.22.37-2.9.89-4.08,2.78-1.65,2.65-2.2,6.12-1.58,10.04.11.72.53,1.31,1.1,1.67Z"
            fill="#93ace1"
          />
          <path
            d="m65.73,108.65c.51-.24.94-.65,1.18-1.2.55-1.21,0-2.64-1.21-3.18-2.55-1.15-4.33-2.68-5.14-4.44-.16-.34-.13-.54.16-1.57.44-1.5,1.09-3.78-.41-7.02-1.04-2.25-2.82-3.1-4.12-3.72-1.13-.54-1.64-.81-1.99-1.62-.01-.15.14-.86.24-1.33.52-2.48,1.5-7.11-5.45-10.15-1.22-.53-2.64.02-3.17,1.24-.53,1.22.02,2.64,1.24,3.17,3.36,1.47,3.13,2.56,2.67,4.75-.27,1.3-.58,2.76.06,4.24,1.08,2.5,2.96,3.39,4.34,4.05,1.12.53,1.53.77,1.82,1.39.75,1.61.51,2.43.15,3.66-.36,1.23-.84,2.91.09,4.93,1.31,2.83,3.92,5.18,7.54,6.81.66.3,1.39.27,2-.01Z"
            fill="#93ace1"
          />
          <path
            d="m120.49,263.77c.51-.23.95-.63,1.2-1.18.57-1.21.05-2.64-1.16-3.2-2.53-1.19-4.28-2.75-5.07-4.53-.15-.34-.12-.54.19-1.57.46-1.5,1.15-3.76-.29-7.03-1.01-2.27-2.77-3.15-4.06-3.78-1.12-.56-1.63-.84-1.97-1.65,0-.15.15-.86.26-1.32.56-2.48,1.61-7.08-5.28-10.24-1.21-.55-2.64-.02-3.19,1.19-.55,1.21-.02,2.64,1.19,3.19,3.33,1.52,3.08,2.62,2.59,4.79-.29,1.29-.63,2.75,0,4.24,1.04,2.51,2.91,3.44,4.27,4.12,1.11.55,1.52.8,1.8,1.42.72,1.62.47,2.43.09,3.66-.38,1.22-.89,2.9.01,4.93,1.26,2.85,3.83,5.25,7.43,6.94.66.31,1.38.29,2,.02Z"
            fill="#93ace1"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
