import { IsCodeUsableInput, RoiwardService } from '@/services/freeflight-api';

export const validatePinCode = async (
  requestBody: IsCodeUsableInput
): Promise<string> => {
  var response =
    await RoiwardService.postApiServicesAppRoiwardPostPincodeStatus({
      requestBody,
    });
  return response;
};
