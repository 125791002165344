import styled from '@emotion/styled';

export const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: 576px) {
    max-width: 540px !important;
  }

  @media (min-width: 768px) {
    max-width: 720px !important;
  }

  @media (min-width: 992px) {
    max-width: 960px !important;
  }

  @media (min-width: 1200px) {
    max-width: 1230px !important;
  }
`;

export const Title = styled.h2`
  display: flex;
  gap: 8px;
  color: #3c3c3c;
`;
export const Description = styled.p`
  color: #3c3c3c;
`;
export const LegalText = styled.p`
  font-size: 12px;
  color: #3c3c3c;
`;

export const Text = styled.div`
  margin-bottom: 0.5em;
  color: #3c3c3c;
  font-size: 16px;
  line-height: 1.5;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 2em 0;
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const ConditionsList = styled.ul`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  flex-direction: column;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid rgb(234, 234, 235);

  @media (max-width: 576px) {
    & button {
      width: 100%;
    }
  }
`;
