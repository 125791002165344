import styled from '@emotion/styled';
import { Button as MuiButton } from '@mui/material';

export const CtaButton = styled(MuiButton)`
  border: none;
  text-decoration: none;
  display: flex;
  align-items: center;
  border-radius: 25px;
  cursor: pointer;
  width: max-content;
  margin: auto;
  padding: 24px 40px;
  font-size: 16px;
  background-color: white;
`;
