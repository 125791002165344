import { DocumentTitle } from '@/components/DocumentTitle';
import { Header } from '@/components/Header';
import { Hero } from '@/components/Hero';
import { LmnFooter } from '@/components/LmnFooter';
import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import * as Styled from './BookingPage.styled';
import { Content } from './components/Content';

export function BookingPage() {
  const [brandConfig] = useStore((state) => [state.brandConfig]);
  const { t } = useTranslate();

  const { content, theme } = brandConfig;
  const translationkeys = content;
  const { primaryColor } = theme.data.attributes;

  return (
    <>
      <DocumentTitle
        title={t(
          content.general.tabName.key,
          content.general.tabName.placeholders
        )}
      />
      <Header />
      <Hero
        title={t(content.booking.title)}
        backgroundUrl={content.hero.backgroundUrl}
      >
        <Styled.CtaButton
          onClick={() =>
            window.open(translationkeys.booking.cta.url, '_blank')
          }
          style={{ color: primaryColor }}
        >
          {t(translationkeys.booking.cta)}
        </Styled.CtaButton>
      </Hero>
      <Content />
      <LmnFooter />
    </>
  );
}
