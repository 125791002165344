import { StrapiService } from '@/services/codegen-welfare';
import { createStore } from 'zustand';
import type { BrandConfigProps } from './utils/brandConfigProps';

export type CreateConfigStore = ReturnType<typeof createConfigStore>;
export interface StoreStateProps {
  brandConfig: BrandConfigProps | null;
  travelSearchConfig: {
    heroSection: {
      title: string;
      subtitle: string;
    };
    productSection: {
      title: string;
      subtitle: string;
      description: string;
    };
    backgroundURL: string;
  };
  getTravelSearchConfig: (id: string) => void;
}

export const createConfigStore = ({ brandConfig }) => {
  return createStore<StoreStateProps>()((set, get) => ({
    brandConfig,
    travelSearchConfig: null,
    getTravelSearchConfig: async (id: string) => {
      try {
        const strapiConfig =
          await StrapiService.getApiServicesAppStrapiGetConfig({
            path: `search-landings/${id}?populate=*`,
          });

        const dataAttributes = strapiConfig.data.attributes;

        const heroSection = dataAttributes.hero;
        const productSection = dataAttributes.explanation;
        const backgroundURL =
          dataAttributes.hero_background.data.attributes.url;

        set({
          travelSearchConfig: { heroSection, productSection, backgroundURL },
        });
      } catch (error) {
        console.log('🚀 ~ getlandingContent: ~ err:', error);
      }
    },
  }));
};
