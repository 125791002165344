/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateFFVoucherResponseDto } from '../models/CreateFFVoucherResponseDto';
import type { FreeFlightVoucherInput } from '../models/FreeFlightVoucherInput';
import type { IsCodeUsableInput } from '../models/IsCodeUsableInput';
import type { PinCodeReportInputDto } from '../models/PinCodeReportInputDto';
import type { PinCodeResponseDtoPagedResultDto } from '../models/PinCodeResponseDtoPagedResultDto';
import type { ReportRequestDto } from '../models/ReportRequestDto';
import type { ResendVoucherResponseDto } from '../models/ResendVoucherResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RoiwardService {

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiServicesAppRoiwardCreatePinCodesReport({
        requestBody,
    }: {
        requestBody?: ReportRequestDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/Roiward/CreatePinCodesReport',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns PinCodeResponseDtoPagedResultDto Success
     * @throws ApiError
     */
    public static postApiServicesAppRoiwardPinCodeReport({
        requestBody,
    }: {
        requestBody?: PinCodeReportInputDto,
    }): CancelablePromise<PinCodeResponseDtoPagedResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/Roiward/PinCodeReport',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static getApiServicesAppRoiwardGetFieldName({
        obj,
        field,
    }: {
        obj?: any,
        field?: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/services/app/Roiward/GetFieldName',
            query: {
                'obj': obj,
                'field': field,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiServicesAppRoiwardIsVoucherCodeUsable({
        voucherCode,
    }: {
        voucherCode?: string,
    }): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/Roiward/IsVoucherCodeUsable',
            query: {
                'voucherCode': voucherCode,
            },
        });
    }

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static postApiServicesAppRoiwardPostPincodeStatus({
        requestBody,
    }: {
        requestBody?: IsCodeUsableInput,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/Roiward/PostPincodeStatus',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns ResendVoucherResponseDto Success
     * @throws ApiError
     */
    public static postApiServicesAppRoiwardResendVoucher({
        requestBody,
    }: {
        requestBody?: IsCodeUsableInput,
    }): CancelablePromise<ResendVoucherResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/Roiward/ResendVoucher',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns CreateFFVoucherResponseDto Success
     * @throws ApiError
     */
    public static postApiServicesAppRoiwardCreateFreeFlightVoucher({
        requestBody,
    }: {
        requestBody?: FreeFlightVoucherInput,
    }): CancelablePromise<CreateFFVoucherResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/services/app/Roiward/CreateFreeFlightVoucher',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
