import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import * as Styled from './LmnFooter.styled';

export function LmnFooter() {
  const { t } = useTranslate();
  const [brandConfig] = useStore((state) => [state.brandConfig]);
  const { key, placeholders, links } = brandConfig.content.general.footer;
  return (
    <Styled.Container>
      <Styled.Text>{t(key, placeholders)}</Styled.Text>
      {links && (
        <Styled.LinksWrapper>
          {links.map((e, index) => (
            <a
              key={`${index}_footer_links`}
              href={e.placeholders.url}
              target="_blank"
            >
              {t(e.key)}
            </a>
          ))}
        </Styled.LinksWrapper>
      )}
    </Styled.Container>
  );
}
