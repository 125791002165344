import { FreeFlightVoucherInput } from '@/services/freeflight-api';
import { useStore } from '@/store/useStore';
import { createVoucher } from '../utils/createVoucher';
import { validatePinCode } from '../utils/validatePinCode';

const autoRedeem = true;

export const useRedeemCode = () => {
  const brandConfig = useStore((state) => state.brandConfig);
  const tenantName = brandConfig.tenantName;
  const language = brandConfig.content.general.location.language;

  const redeemPincode = async (
    formData: {
      firstName: string;
      lastName: string;
      email: string;
      email2: string;
      phone: string;
    },
    pinCode: string
  ) => {
    const { firstName, lastName, email, phone } = formData;

    var pinCodeStatus = await validatePinCode({
      tenantName: tenantName,
      pinCodes: [pinCode],
    });

    if (pinCodeStatus === 'Ok') {
      var requestBody: FreeFlightVoucherInput = {
        fullName: `${firstName} ${lastName}`,
        email,
        phone,
        sendingMethod: 0,
        tenantName: tenantName,
        pinCode: [pinCode],
        defaultLang: language,
        autoRedeem,
      };

      const request = await createVoucher(requestBody);
      return request;
    } else {
      return { success: false, errorMsg: pinCodeStatus };
    }
  };

  const checkPinCode = async (pinCode: string) =>
    await validatePinCode({
      tenantName: tenantName,
      pinCodes: [pinCode],
    });

  return { redeemPincode, checkPinCode };
};
