import { useStore } from '@/store/useStore';
import * as React from 'react';

export function Favicon() {
  const brandConfig = useStore((state) => state.brandConfig);

  const favicon = brandConfig.theme.data.attributes.favicon.data.attributes.url;

  React.useEffect(() => {
    const faviconElement = document.getElementById('favicon');

    if (faviconElement) {
      faviconElement.setAttribute('href', favicon);
    }
  }, []);

  return null;
}
