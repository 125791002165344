import { DocumentTitle } from '@/components/DocumentTitle';
import { Header } from '@/components/Header';
import { Hero } from '@/components/Hero';
import { LmnFooter } from '@/components/LmnFooter';
import { RedeemForm } from '@/components/RedeemForm';
import { useStore } from '@/store/useStore';
import { useTranslate } from '@tolgee/react';
import { MainContent } from './components/MainContent';

export function MainPage() {
  const [brandConfig] = useStore((state) => [state.brandConfig]);
  const { t } = useTranslate();
  const translationkeys = brandConfig.content;


  return (
    <>
      <DocumentTitle
        title={t(
          translationkeys.general.tabName.key,
          translationkeys.general.tabName.placeholders
        )}
      />
      <Header />
      <Hero
        title={t(
          translationkeys.hero.title.key,
          translationkeys.hero.title.placeholders
        )}
        backgroundUrl={translationkeys.hero.backgroundUrl}
      >
        <RedeemForm />
      </Hero>
      <MainContent />
      <LmnFooter />
    </>
  );
}
