import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const InnerContainer = styled.div`
  display: flex;
  height: 40px;
  background-color: white;
  border-radius: 6px;
  padding: 8px 16px;
  color: #3c3c3c;
  font-weight: 500;
  font-size: 14px;
  align-items: center;

  @media (max-width: 1024px) {
    display: none;
  }
`;
export const InnerContainerMobile = styled.div`
  display: flex;
  flex: 1;
  margin-right: 16px;
  flex-direction: column;
  height: 40px;
  background-color: white;
  border-radius: 6px;
  padding: 8px 8px;
  color: #3c3c3c;
  overflow: hidden;
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0px 12px 0px 24px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const Menu = styled.div`
  background-color: #f5f5f5;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px 12px 24px;
  box-shadow:
    0px 10px 20px rgba(0, 0, 0, 0.04),
    0px 2px 10px rgba(0, 0, 0, 0.04),
    0px 0px 4px rgba(0, 0, 0, 0.02);
`;

export const Divider = styled.div`
  background-color: #eaeaeb;
  height: 24px;
  min-width: 1px;
  margin: 0px 8px;
`;

export const LogoContainer = styled.a<{ $hideLogo: boolean }>`
  @media (max-width: 1024px) {
    ${({ $hideLogo }) =>
      $hideLogo &&
      css`
        display: none;
      `}
  }
`;

export const Logo = styled.img`
  height: 40px;
`;
export const Button = styled.a`
  text-decoration: none;
  height: 40px;
  display: flex;
  padding: 0px 16px;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0 0 0 1px #eaeaeb;
  cursor: pointer;
  color: #3c3c3c;
  background-color: white;
  font-size: 14px;
`;

export const AssisanceButton = styled.div`
  border: none;
  text-decoration: none;
  height: 40px;
  display: flex;
  padding: 0px 16px;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0 0 0 1px #eaeaeb;
  cursor: pointer;
  color: white;
  font-size: 14px;
`;

export const CallToButton = styled.a`
  border: none;
  text-decoration: none;
  height: 40px;
  display: flex;
  padding: 0px 16px;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0 0 0 1px #eaeaeb;
  cursor: pointer;
  color: white;
  font-size: 14px;
  gap: 8px;
  justify-content: center;
`;

export const Text = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;

export const MobileLocationText = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #3c3c3c;
  border: 0px;
  line-height: 14px;
  height: 14px;
  height: auto;
  margin-top: 0px;
  text-overflow: ellipsis;
`;

export const MobileDatesText = styled.span`
  font-size: 12px;
  line-height: 14px;
  height: 14px;
  font-style: normal;
  font-weight: 600;
  color: #3c3c3c;
  border: 0px;
  height: auto;
  margin-top: 0px;
  text-overflow: ellipsis;
`;

export const AssistanceBox = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  margin-top: 8px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  box-shadow:
    0px 10px 20px rgba(0, 0, 0, 0.04),
    0px 2px 10px rgba(0, 0, 0, 0.04),
    0px 0px 4px rgba(0, 0, 0, 0.02);
`;
